import { Col, Row } from "antd";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import FooterLogo from "../../assets/images/footerLogo.svg";
import { Link, useLocation } from "react-router-dom";
import ScanImg from "../../assets/images/qrcode.png";
import useStore from "../../store";
import PageSpinner from "../PageSpinner/PageSpinner";


const Footer: React.FC<any> = observer(() => {
	const {
		AUTH: { footerData, getFooterData },
	} = useStore();
	const offiecDetails = footerData?.office_address;
	const qrCode = footerData?.qr_section;
	const logoSection = footerData?.logo_section;
	const copySection = footerData?.footer_bottom;
	const socialList = footerData?.social_section;
	const location = useLocation();
	const isActiveMenu = (url : string) : boolean => {
		return location.pathname===url
	}

	useEffect(() => {
		getFooterData();
	}, [getFooterData]);

	return footerData ? (
		<>
			<footer>
				<div className="container FooterContainer">
					<Row>
						<Col xs={24} sm={12} md={6} xl={6} xxl={6}>
							<div className="leftFooterContent">
								<h4 className="footerHeading">
									{offiecDetails?.title !== "" ? (
										<span
											dangerouslySetInnerHTML={{
												__html: offiecDetails?.title,
											}}
											className="footerHeading"
										></span>
									) : (
										<span>Corporacte Office</span>
									)}
								</h4>
								{offiecDetails?.description ? (
									<div
										dangerouslySetInnerHTML={{
											__html: offiecDetails?.description,
										}}
										className="footerPera"
									></div>
								) : null}
							</div>
							<div>
								<div className="socialIcons">
									<ul>
										{socialList && socialList.map((data: any) => {
											return (
													<li key={data?.id}>
														<a href={data.url} target="_blank" rel="noreferrer">
															<img
																src={data.logo}
																alt={data.name}
																className="icon"
															/>
														</a>
													</li>
											);
										})}
									</ul>
								</div>
							</div>
						</Col>
						<Col xs={24} sm={12} md={5} xl={4} xxl={3}>
							<div className="usefulLinks">
								<h4 className="footerHeading">
									<span>Useful Links</span>
								</h4>
								<div className="linkList">
									{footerData.menu_section.map((data: any) => {
										return (
											<Link key={data.id} to={data.url} className={isActiveMenu(data.url) ? "active" : ""}>
												{data.title}
											</Link>
										);
									})}
								</div>
							</div>
						</Col>
						<Col xs={24} sm={12} md={6} xl={6} xxl={4}>
							<div className="QrCode">
								<h4 className="footerHeading">
									{qrCode?.title !== "" ? (
										<span
											dangerouslySetInnerHTML={{
												__html: qrCode?.title,
											}}
										></span>
									) : (
										<span>QR Code for Website</span>
									)}
								</h4>
								{<img src={ScanImg} alt="qrCode Image" />}
							</div>
						</Col>
						<Col xs={24} sm={12} md={7}  xl={8} xxl={10}className="beforeContent">
							<div className="rightFooterContent">
								<Link
									to="/"
									onClick={() => {
										window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
									}}
								>
									{
										<img
											src={
												logoSection?.image !== ""
													? logoSection?.image
													: FooterLogo
											}
											alt="footer Logo"
										/>
									}
								</Link>

								{logoSection?.title !== "" ? (
									<p className="rightFooterPera">We Are Tech Friendly.</p>
								) : null}
							</div>
						</Col>
					</Row>
				</div>
			</footer>
			<div className="bottomFooter">
				<Row className="container">
					<div className="bottomFooterLinks ">
						{copySection?.title !== "" ? (
							<p>{copySection?.title}</p>
						) : (
							<p>Copyright © 2023 Shree Nandan Courier Limited</p>
						)}

						<div className="rightSideLinks">
							<Link to={`${copySection?.btn_url}`} target="_blank" rel="noreferrer">
								{`${copySection?.btn_text}`}
							</Link>
						</div>
					</div>
				</Row>
			</div>
		</>
	) : (
		<PageSpinner />
	);
});

export default Footer;
