import { observer } from "mobx-react";
import React, { useEffect } from "react";
//import TrackBanner from "../../../../assets/images/trackBanner.svg";
import { Col, Row } from "antd";
import useStore from "../../../../store";
//import TrackJson from "../track_shipment.json";

const TrackShipmentBanner: React.FC = observer(() => {
	//const TrackData = TrackJson?.track_shipment?.banner;
	const {
		TRACKDATA: { trackBannerData, getTrackBanner },
	} = useStore();
	//console.log("trackBannerData",trackBannerData);

	useEffect(() => {
		getTrackBanner();
	}, [getTrackBanner]);
	return trackBannerData ? (
		<section className="trackContainer">
			<div className="container">
				<Row className="alignCenter">
					<Col xs={24} sm={24} md={14} className="trackContent">
						{trackBannerData.title ? (
							<div
								dangerouslySetInnerHTML={{ __html: trackBannerData?.title }}
								className="heading"
							></div>
						) : null}

						<div className="bannerPera">
							{trackBannerData.description ? (
								<div
									dangerouslySetInnerHTML={{
										__html: trackBannerData?.description,
									}}
								></div>
							) : null}
						</div>
					</Col>
					<Col xs={24} sm={24} md={10}>
						{trackBannerData.image ? (
							<img src={trackBannerData.image} alt="about us banner" />
						) : null}
					</Col>
				</Row>
			</div>
		</section>
	) : null;
});
export default TrackShipmentBanner;
