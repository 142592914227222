import React, { useState } from "react";
import { observer } from "mobx-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Drawer } from "antd";
import SocialIcons from "./SocialIcons";
import useStore from "../../../store";
import ClientLogin from "../../ClientLogin/Component/ClientLoginModal";
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import ChangePasswordModal from "../../ClientLogin/Component/ChangePasswordModal";
import LogoutModal from "../../ClientLogin/Component/LogoutModal";

interface AddProps {
	closeDrawer: () => void;
	open?: boolean;
	menuList?: any;
}

const responsiveMenu: React.FC<AddProps> = observer(
	({ closeDrawer, open, menuList }) => {
		const { AUTH: { headerData }, } = useStore();
		const socialList = headerData?.social_section;
		const [clientmodalVisible, setClientModal1Visible] = useState(false);
		const [logoutModalvisible, setLogoutModalvisible] = useState(false);
		const [isOpen, setIsOpen] = useState(false);
		const [toggle, setToggle] = useState(false)
		const location = useLocation();
		const navigate = useNavigate();
		const isActiveMenu = (url: string): boolean => {
			return location.pathname === url;
		};
		// const handleLogout = () => {
		// 	doLogout().then((data: any) => {
		// 		ClientLogIn.clientNotify.success({
		// 			message: data.STATUS.NOTIFICATION[0],
		// 		});
		// 		navigate("/")
		// 	});
		// }
		const handleLogoutOpen = () => {
			setLogoutModalvisible(true);
		}
		const handleAccount = () => {
			navigate("/my-account");
			closeDrawer();

		}
		const handleDelModal = () => {
			setIsOpen(!isOpen);
		};
		const handleClientOpen = () => {
			setClientModal1Visible(true);

		};
		const handleClientClose = () => {
			setClientModal1Visible(false);
		};

		const handleModalClose = () => {
			setClientModal1Visible(false);
			setLogoutModalvisible(false);
		};

		const isToken = localStorage.getItem('token')
		return menuList ? (
			<Drawer placement="right" onClose={closeDrawer} open={open} className="responsiveDrawer">
				<div className="responsiveMenu">
					<ul className="menuWrapper">
						{menuList.map((data: any) => {
							return (
								<li className={isActiveMenu(data.url) ? 'active' : ""} key={data.id} >
									{data.sub_menu.length > 0 ? <Link to={data.url} onClick={() => setToggle(!toggle)} >{data.title} <span className="arrow">{!toggle ? <DownOutlined /> : <UpOutlined />}</span> </Link> : <Link onClick={closeDrawer} to={data.url}>{data.title}</Link>}
									{toggle && (
										<ul className="submenu">
											{data.sub_menu.map((item: any) => {
												return (
													<li key={item.id} className={isActiveMenu(item.url) ? 'active' : ""}>
														<Link onClick={closeDrawer} to={item.url}>
															{item.title}
														</Link>
													</li>
												);
											})}

										</ul>
									)}
									{toggle}
								</li>

							);
						})}

						{isToken ?
							<>
								<div onClick={handleAccount} className="clientMenu">My Account</div>
								<div onClick={() => handleDelModal()} className="clientMenu">Change Password</div>
								<div onClick={() => handleLogoutOpen()} className="clientMenu">Logout</div>
							</>

							:
							<div className="clientLogin">
								<Button className="loginBtn" onClick={handleClientOpen}>Client Login</Button>
							</div>
						}
					</ul>

					<ClientLogin
						visible={clientmodalVisible}
						close={handleModalClose}
						handleClientOpen={handleClientClose}
						closeDrawer={closeDrawer}
						open={open}
					/>
					<ChangePasswordModal
						handleCancel={handleDelModal}
						open={isOpen}
					/>
					<LogoutModal handleClientOpen={handleLogoutOpen} close={handleModalClose} visible={logoutModalvisible} />
					<SocialIcons socialList={socialList} />
				</div>
			</Drawer>
		) : null;
	}
);
export default responsiveMenu;
