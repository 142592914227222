import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormBox, InputBox } from "../../AntdAddons";
import { observer } from "mobx-react";
import useStore from "../../../store";
import moment from "moment";
import { Button, Form, Image, Pagination } from "antd";
import { Messages } from "../../../config/Message/Common";
import { displayFormats } from "../../../config/Global";
import { ClientLogIn } from "../Component/validation";
import { ReactComponent as AllPodZip } from "../../../assets/images/download_zip.svg";
import { ReactComponent as ViewPod } from "../../../assets/images/view.svg";
import { Constant } from "../../../config/Constant";

const ListComponent: React.FC = observer(() => {
    const { CLIENTLIST: { getClientData, locationDataTotal, clientListData, podZipData, getDownloadPODData, setClientListData, getLocationList,
        locationData },
        AUTH: { clientDetails, user, checkPrivileges },
    } = useStore();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [dateRange, setDateRange] = useState([]);
    const [formList] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [podImg, setPodImg] = useState(false);

    const handleChange = () => {
        formList
            .validateFields()
    };

    const fetchClientData = (
        page: number,
        dateRange: any
    ) => {
        const startDate = moment(dateRange[0]).format(displayFormats.SERIAL_DATE);
        const endDate = moment(dateRange[1]).format(displayFormats.SERIAL_DATE);


        getClientData({
            page: page ?? 1,
            per_page: 10,
            purpose: "2",
            catg_id: "2",
            br_name: clientDetails.br_name,
            client_id: formList.getFieldValue("client_id") ? formList.getFieldValue("client_id") : clientDetails.id,
            center_id: formList.getFieldValue("center_id") ? formList.getFieldValue("center_id") : clientDetails.center_id,
            ct_id: clientDetails.ct_id,
            from_date: startDate,
            to_date: endDate,
        });
    };
    const handleLocationSelectFocus = () => {
        getLocationList({
            client_id: clientDetails.id,
            purpose: "11",
            is_active: "1"
        });
    };
    const rerenderListing = () => {
        const fromTODate = formList.getFieldValue("date")
        const CenterID = formList.getFieldValue("center_id")
        const ClientId = formList.getFieldValue("client_id")
        const from_date = fromTODate && fromTODate[0] ? moment(fromTODate[0]).format(displayFormats.SERIAL_DATE) : ''
        const to_date = fromTODate && fromTODate[1] ? moment(fromTODate[1]).format(displayFormats.SERIAL_DATE) : ''
        if (!from_date || !to_date) {
            // ClientStore.clientListData = null;
            setClientListData(null)
        }
        setDateRange(fromTODate);
        if (CenterID && ClientId && from_date && to_date) {
            fetchClientData(1, fromTODate);
        }
        else if (from_date && to_date && clientDetails?.ct_id === Constant.CLIENT_TYPE.NORMAL) {
            fetchClientData(1, fromTODate);
        }
    }
    const onPaginationChange = (page: number) => {
        const getDate = formList.getFieldValue("date")
        setCurrentPage(page);
        fetchClientData(page, getDate);
    };
    const columnDefs = [
        {
            title: "Booking Date",
            dataIndex: "bookingdate",
            key: "bookingdate",
        },
        {
            title: "Consignment Number",
            dataIndex: "consignmentnumber",
            key: "consignmentnumber",
        },
        {
            title: "Consignee",
            dataIndex: "consignee",
            key: "consignee",
        },
        {

            title: "Service Type",
            dataIndex: "servicetype",
            key: "servicetype",

        },
        {
            title: "To City",
            dataIndex: "tocity",
            key: "tocity",
        },
        {
            title: "To Pincode",
            dataIndex: "topincode",
            key: "topincode",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            hide: checkPrivileges("#12231#") ? false : true

        },
        {
            title: "Delivery Date",
            dataIndex: "deliverydate",
            key: "deliverydate",
            hide: checkPrivileges("#12230#") ? false : true
        },
        {
            title: "Weight",
            dataIndex: "weight",
            key: "weight",
            hide: checkPrivileges("#12227#") ? false : true
        },
        {
            title: "Charges",
            dataIndex: "charges",
            key: "charges",
            hide: checkPrivileges("#12228#") ? false : true
        },
        {
            title: "POD",
            dataIndex: "pod",
            key: "pod",
            hide: checkPrivileges("#12229#") ? false : true
        }

    ];

    // const filteredColumns = columnDefs.filter(column => {
    //     if (clientListData?.length < 1) {
    //         if (
    //             (column.key === 'weight' || column.key === 'charges' || column.key === 'pod')
    //         ) {
    //             return false; // Hide specified columns when clientListData is empty
    //         }
    //     } if (
    //         (column.key === 'weight' && lckFlags?.wie_flag < 1) ||
    //         (column.key === 'charges' && lckFlags?.cie_flag < 1) ||
    //         (column.key === 'pod' && lckFlags?.pod_flag < 1)
    //     ) {
    //         return false; // Hide columns based on flag value 0
    //     }
    //     return true; // Show columns otherwise
    // });
    // const filteredColumns = columnDefs.filter(column => {
    //     const { wie_flag, cie_flag, pod_flag } = lckFlags || {};
    //     return (
    //         !(
    //             clientListData?.length < 1 &&
    //             ['weight', 'charges', 'pod'].includes(column.key)
    //         ) &&
    //         !(
    //             {
    //                 weight: wie_flag,
    //                 charges: cie_flag,
    //                 pod: pod_flag,
    //             }[column.key] < 1
    //         )
    //     );
    // });
    // const allDownload = clientListData?.length < 1;
    const base64ToArrayBuffer = (base64: any) => {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return bytes.buffer;
    };

    useEffect(() => {
        if (podZipData) {
            convertAndDownload();
        }
    }, [podZipData])

    const convertAndDownload = () => {
        // Convert Base64 string to ArrayBuffer
        const arrayBuffer = base64ToArrayBuffer(podZipData);

        // Create a Blob object from ArrayBuffer
        const blob = new Blob([arrayBuffer], { type: 'application/zip' });

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'application.zip';

        // Trigger the download
        link.click();

        // Clean up
        window.URL.revokeObjectURL(link.href);
    };
    const downloadAllPodZip = () => {
        const payload = {
            client_id: clientDetails.id,
            center_id: clientDetails.center_id,
            from_date: dateRange[0] ? moment(dateRange[0]).format(displayFormats.SERIAL_DATE) : '',
            to_date: dateRange[1] ? moment(dateRange[1]).format(displayFormats.SERIAL_DATE) : '',
        }
        if (clientDetails.ct_id !== Constant.CLIENT_TYPE.NORMAL) {
            payload.client_id = formList.getFieldValue("client_id");
            payload.center_id = formList.getFieldValue("center_id");
        }
        getDownloadPODData(payload);
    }

    const handleDateRangeClose = () => {
        // Check if both startDate and endDate are empty or blank
        const startDate = moment(dateRange[0]).format(displayFormats.SERIAL_DATE);
        const endDate = moment(dateRange[1]).format(displayFormats.SERIAL_DATE);
        if (!startDate.trim() && !endDate.trim()) {
            // Perform actions when the date range is blank or closed
            // You can manage your data or perform other actions here
        }
    };
    useEffect(() => {
        const todayDate = new Date()
        formList.setFieldValue("date", [moment(), moment()])
        if (formList.getFieldValue("date")) {
            setDateRange(formList.getFieldValue("date"));
        }
        if (clientDetails?.id && clientDetails?.ct_id === Constant.CLIENT_TYPE.NORMAL) {
            fetchClientData(1, todayDate);
        }
    }, [getClientData, user]);

    useEffect(() => {
        return () => {
            setClientListData(null)
        }
    }, [setClientListData])

    return (
        <>
            <div className="clientListing">
                <h1>Booking Details</h1>
                <div className="clientDateRange">
                    <div className="formBg">
                        <div className="clientHeader">
                            <div className="userName">
                                <span style={{ color: "#000" }}>Name : </span>
                                <span>{clientDetails?.name + (clientDetails?.br_name ? ' - ' + clientDetails.br_name : '')}
                                </span>
                            </div>
                        </div>
                        <div className="formRight">
                            {
                                clientDetails?.ct_id === Constant.CLIENT_TYPE.CORPORATE || clientDetails?.ct_id === Constant.CLIENT_TYPE.BARTER ? (
                                    <FormBox form={formList} className="locationList" onChange={handleChange}>
                                        <div className="inputField">
                                            <label>Location</label>
                                            <InputBox.Select
                                                name="client_id"
                                                className="inputText"
                                                required
                                                showSearch
                                                rules={ClientLogIn.validation.clientLocation}
                                                onFocus={handleLocationSelectFocus}
                                                // onSelect={handleLocationSelect}
                                                onChange={(e) => {
                                                    const centerobj = locationData.find((item: any) => { return item.id === e })
                                                    formList.setFieldValue("center_id", centerobj.center_id)
                                                    rerenderListing()
                                                    handleChange()
                                                }}
                                                options={{
                                                    list: locationData,
                                                    valueKey: "id",
                                                    textKey: "name",
                                                }}
                                                placeholder="Please Select"

                                            />
                                            <InputBox.Text
                                                name="center_id"
                                                hidden
                                                className="inputText"
                                            />
                                        </div>
                                    </FormBox>
                                ) : null
                            }
                            <FormBox form={formList}>
                                <div className="inputField">
                                    <label>Booking Date</label>
                                    <InputBox.DateRangePicker name="date"
                                        onChange={() => {
                                            rerenderListing()
                                            handleChange()
                                        }}
                                        onPanelChange={handleDateRangeClose}
                                        format={displayFormats.DATE_FORMAT}
                                        rules={ClientLogIn.validation.fromToDate}
                                    />
                                </div>
                            </FormBox>
                        </div>
                    </div>
                </div>
                {(clientListData?.length > 0 && clientListData.filter((item: any) => item.pod !== null).length > 0 && checkPrivileges("#12229#")) &&
                    (<div className="downloadAll" onClick={downloadAllPodZip}> Download All POD`s<AllPodZip /></div>)}
                <div className="clientTableList">
                    <table>
                        <thead>
                            <tr>
                                {
                                    columnDefs.filter((column: any) => column.hide !== true).map((data: any, index: number) => {
                                        return (
                                            <th key={index}>{data.title}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        {clientListData?.length > 0 ? (
                            <tbody>
                                {
                                    clientListData?.map((reportdata: any, index: number) => {
                                        return (
                                            <>
                                                <tr key={index}>

                                                    {/* {/ <td data-label="ID">{reportdata.id}</td> /} */}
                                                    <td data-label="Booking Date">{moment(reportdata.bk_date).format(displayFormats.DATE_FORMAT)}</td>
                                                    <td data-label="Consignment Number"><Link to={`/track-shipment/${reportdata.con_no}`}>{reportdata.con_no}</Link></td>
                                                    <td data-label="Consignee">{reportdata.r_name}</td>
                                                    <td data-label="Service Type">{reportdata.service_type
                                                    }</td>
                                                    <td data-label="To City">{reportdata.r_city}</td>
                                                    <td data-label="To Pincode">{reportdata.pincode}</td>

                                                    {
                                                        checkPrivileges("#12231#") &&
                                                        <td data-label="Status">{reportdata.status_name ? reportdata.status_name : '-'}</td>
                                                    }
                                                    {
                                                        checkPrivileges("#12230#") &&
                                                        <td data-label="Delivery Date">{reportdata.ds_date !== null ? moment(reportdata.ds_date).format(displayFormats.DATE_FORMAT) : "-"}
                                                        </td>
                                                    }
                                                    {/* {lckFlags.wie_flag === 1 &&
                                                        <td data-label="Weight">{reportdata.c_weight ? reportdata.c_weight : '-'}</td>
                                                    } */}
                                                    {

                                                        checkPrivileges("#12227#") && <td data-label="Weight">{reportdata.c_weight ? reportdata.c_weight : '-'}</td>
                                                    }
                                                    {

                                                        checkPrivileges("#12228#") && <td data-label="Charges">{reportdata.gross ? reportdata.gross : '-'}</td>
                                                    }
                                                    {

                                                        checkPrivileges("#12229#") && <td data-label="POD">{
                                                            reportdata.pod ?
                                                                <div className="viewImg-bth">
                                                                    <Button onClick={() => { setVisible(true); setPodImg(reportdata.pod); }} className="text-btn">
                                                                        <ViewPod /></Button>
                                                                    <Image style={{ display: "none" }} preview={{
                                                                        visible, src: (`${podImg}`), onVisibleChange: (value) => { setVisible(value); },
                                                                    }}
                                                                    />
                                                                </div>
                                                                :
                                                                '-'
                                                        }</td>
                                                    }

                                                    {/* {lckFlags.cie_flag === 1 &&
                                                        <td data-label="Charges">{reportdata.gross ? reportdata.gross : '-'}</td>
                                                    }

                                                    {lckFlags.pod_flag === 1 &&
                                                        <td data-label="POD" align="center">{
                                                            reportdata.pod ?
                                                                <div className="viewImg-bth">
                                                                    <Button onClick={() => { setVisible(true); setPodImg(reportdata.pod); }} className="text-btn"><ViewPod /></Button>
                                                                    <Image style={{ display: "none" }} preview={{
                                                                        visible, src: (`${podImg}`), onVisibleChange: (value) => { setVisible(value); },
                                                                    }}
                                                                    />
                                                                </div>
                                                                :
                                                                '-'
                                                        }</td>
                                                    } */}
                                                    {/* {/ <td data-label="Location">{reportdata.location_name}</td> /} */}
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={8} className="recordCol">
                                        {Messages.NO_RECORD}
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
            {clientListData?.length > 0 && (
                <div className="paginationData">
                    <Pagination
                        defaultCurrent={currentPage}
                        total={locationDataTotal}
                        onChange={onPaginationChange}
                        showSizeChanger={false}
                        showTotal={(total, range) =>
                            locationDataTotal !== null && locationDataTotal !== ""
                                ? `${range[0]}-${range[1]} of ${total} items`
                                : ""
                        }
                    />
                </div>
            )}
        </>
    );
});

export default ListComponent;
