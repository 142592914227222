import React from "react";
import { Layout } from "antd";
import { Outlet} from "react-router-dom";
import { observer } from "mobx-react";

import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TrackBtn from "../../../components/TrackingPage/TrackBtn";
import OfferBtn from "../../../components/MessageIcon/OfferBtn";
// import useStore from "../../../store";

const AuthLayout: React.FC = observer(() => {
	// const navigate = useNavigate();

	// const { AUTH } = useStore();
	// const { token } = AUTH;

	// useEffect(() => {
	// 	const token = localStorage.getItem("token")
	// 	if (token) {
	// 		navigate("/my-account");
	// 	} 
	// 	// else {
	// 	// 	navigate("/");
	// 	// }
	// }, [token,history]);
	// const navigate = useNavigate();
	// const { AUTH } = useStore();
	// const { token } = AUTH;
	// useEffect(() => {
	// 	if (token) {
	// 		navigate("/");
	// 	}
	// }, [token, history]);
	return (
		<Layout className={`main__page__wrapper has__header`}>
			{/* <HeaderView /> */}
			<Header />
			<TrackBtn />
			<OfferBtn/>
			<Layout.Content className="main__page__content">
				<Outlet />
			</Layout.Content>
			<Footer />
		</Layout>
	);
});

export default AuthLayout;
