import { observer } from "mobx-react";
import React from "react";

const SocialIcons: React.FC<any> = observer(({ socialList }) => {
	return socialList ? (
		<div className="socialIcons">
			<ul>
				{socialList.map((data: any) => {
					return (
						<li key={data.id}>
							<a href={data.url} rel="noreferrer" target="_blank">
								<img src={data.logo} alt={data.name} className="icon" />
							</a>
						</li>
					);
				})}
			</ul>

			<div className="followPageLink">
				<span>Follow this page</span>
			</div>
		</div>
	) : null;
});

export default SocialIcons;
