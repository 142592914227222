import React from "react";
import { useRoutes } from "react-router-dom";

// import ContactUs from "../views/app_pages/ContactUs/Contact";
import AuthLayout from "../views/layouts/AuthLayout";
// import ClientEmailVerify from "../components/ClientLogin/Component/ClientEmailVerify";
import CLientLogin from "../components/ClientLogin";
import VerifyEmail from "../components/verify-client-email";
import VerifyEmailUser from "../components/User-verify-email";
import InternationalTrack from "../views/app_pages/TrackShipment/Components/InternationalTrack";

const ServiceGuide = React.lazy(
	() => import("../views/app_pages/ServiceGuide")
);
const TrackShipment = React.lazy(
	() => import("../views/app_pages/TrackShipment")
);
const PrivacyPolicy = React.lazy(
	() => import("../views/app_pages/PrivacyPolicy")
);
const AboutUs = React.lazy(() => import("../views/app_pages/AboutUs"));
const ChannelPartner = React.lazy(() => import("../views/app_pages/ChannelPartner"));
const PageNotFound = React.lazy(() => import("../views/errors/PageNotFound"));
const Unauthorized = React.lazy(() => import("../views/errors/Unauthorized"));
const Network = React.lazy(() => import("../views/app_pages/Network"));
const Contact = React.lazy(() => import("../views/app_pages/OurContact"));
const Home = React.lazy(() => import("../views/app_pages/Home"));

export default function Router() {
	const element = useRoutes(RouterConfig);
	return element;
}

export const RouterConfig = [
	{
		element: <AuthLayout />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/about-us",
				element: <AboutUs />,
			},
			{
				path: "/franchisee-enquiry",
				element: <ChannelPartner />,
			},
			{
				path: "/network",
				element: <Network />,
			},
			{
				path: "/privacy-policy",
				element: <PrivacyPolicy />,
			},
			{
				path: "/contact-us",
				element: <Contact />,
			},
			{
				path: "/track-shipment/:id",
				element: <TrackShipment />,
			},
			{
				path: "/international-tracking/:id",
				element: <InternationalTrack />,
			},
			{
				path: "/service-guide",
				element: <ServiceGuide />,
			},
			// {
			// 	path: `/verify-client-email`,
			// 	element: <ClientEmailVerify />,
			// },
			{
				path: "/my-account",
				element: <CLientLogin />,
			},
			{
				path: "/unauthorized",
				element: <Unauthorized />,
			},
			{
				path: "/verify-client-email/:token",
				element: <VerifyEmail />,
			},
			{
				path: "/verify-email/:token",
				element: <VerifyEmailUser />,
			},
			{
				path: "*",
				element: <PageNotFound />,
			},
		],
	},
];
