import React from 'react';
import { Button, Col, Form, Modal, Row } from 'antd';
import { InputBox } from '../../AntdAddons';
import { ChangePw, ClientLogIn } from './validation';
import useStore from '../../../store';
// import { useNavigate } from 'react-router-dom';

interface AddProps {
    handleCancel: () => void;
    open?: boolean;
}
const ChangePasswordModal: React.FC<AddProps> = ({ handleCancel, open
    //  handleModal2Open 
}) => {
    const [form] = Form.useForm();
    const { ROOT: { assignErrorToInput } } = useStore();
    const { CHANGEPASSWORD } = useStore();
    // const [disabledSave, setDisabledSave] = useState(true);
    // const { changePassword } = CHANGEPASSWORD;
    const handleChange = () => {
        form
            .validateFields()
            .then(() => {
                // setDisabledSave(false);
            })
            .catch(() => {
                // setDisabledSave(true);
            });
    };
    const handleSubmit = (formData: any) => {
        CHANGEPASSWORD.changePassword(formData)
            .then((res: any) => {
                form.resetFields();
                // navigate("/my-account");
                handleCancel();
                ClientLogIn.clientNotify.success({
                    message: res.STATUS.NOTIFICATION[0],
                });
            })
            .catch((res: any) => {
                assignErrorToInput(form, res?.STATUS)
                ClientLogIn.clientNotify.error({
                    message: res.STATUS.NOTIFICATION[0],
                });
                localStorage.clear();
            })
    };
    const handleCancelModal = () => {
        form.resetFields();
        handleCancel();
    };
    return (
        <>
            <Modal title="Change Password" open={open} centered onCancel={handleCancelModal} footer={false} className='clientModal' destroyOnClose>
                <div className="loginForm" >
                    <div className="loginFormWrap">
                        <Form form={form} id="form_item_path" className='clientLogin' onFinish={handleSubmit}>
                            <Row>
                                <Col span={24}>
                                    <InputBox.Password
                                        name="password"
                                        rules={ChangePw.validation.newPassword}
                                        placeholder="xxxxxxx"
                                        label="New Password"
                                        maxLength={15}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col span={24}>
                                    <InputBox.Password
                                        name="confirm_password"
                                        rules={ChangePw.validation.confirmPassword}
                                        dependencies={['newPassword']}
                                        hasFeedback
                                        placeholder="xxxxxxx"
                                        label="Confirm Password"
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>
                        </Form>
                        <Row className='inlineBtn'>
                            <Col span={11}>
                                <button className="commanBtn" onClick={handleCancelModal}>Cancel</button>
                            </Col>
                            <Col span={11}>
                                <Button className="commanBtn" htmlType="submit" form='form_item_path'>Save</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ChangePasswordModal;