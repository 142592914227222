// import { ApiConstant } from "./ApiConstant";
// import CryptoJS from "crypto-js";

export const displayFormats = {
	DATE_FORMAT: "DD/MM/YYYY",
	TIME_FORMAT: "HH:mm:ss",
	DATE_TIME_FORMAT: "DD/MM/YYYY HH:mm",
	DATE_ONLY: "DD",
	SERIAL_DATE: "YYYYMMDD",
	DATE_TIME_FORMAT2:"DD/MM/YYYY hh:mm A"
};

//for use only without plural name Ex. cite's && state's && pincode's
export const converViewsAddress = (data: any) => {
	data.address = `${data.address1 ? `${data.address1} ,` : ""}${data.address2 ? `${data.address2} ,` : ""
		}${data?.address3 ? `${data.address3} ,` : ""}`;
	data.address =
		data.address +
		` \n ${data.states ? `${data.states.name} ,` : ""}${data.cities ? `${data.cities.name}` : ""
		}${data.pincode ? ` - ${data.pincode.pincode}` : ""}`;
	data.address = data.address.replace(/(,){2,}/gi, ",");
	data.address = data.address.replace(/,$/, "");

	return data.address;
};

// export const smoothScrollTo = (sectionRef: any) => {
//     if (sectionRef.current) {
//         const sectionRect = sectionRef.current.getBoundingClientRect();
//         const scrollOffset = sectionRect.top + window.scrollY + 400;
//         const scrollTimeout = setTimeout(() => {
//             window.scrollTo({
//                 top: scrollOffset,
//                 behavior: "smooth",
//             });
//         }, 1000);
//         return () => {
//             clearTimeout(scrollTimeout);
//         };
//    }
// };
export const smoothScrollTo = (sectionRef: any) => {
	if (sectionRef.current) {
		const scrollTimeout = setTimeout(() => {
			sectionRef.current &&
				sectionRef.current.scrollIntoView({ behavior: "smooth" });
		}, 1000);
		return () => {
			clearTimeout(scrollTimeout);
		};
	}
};

export const MobileValidation = (mobile: string) => {
	if (mobile && typeof mobile === "string") {
		const wrongNumberArr = [
			"6666666666",
			"7777777777",
			"8888888888",
			"9999999999",
			"0000000000",
		];
		const staringStr = ["0", "1", "2", "3", "4", "5"];
		const stringWith = mobile.substring(0, 1);
		if (
			mobile.length === 10 &&
			!wrongNumberArr.includes(mobile) &&
			!staringStr.includes(stringWith)
		) {
			return true;
		}
	}
	return false;
};

export const replaceString = (str: string, find: any, replace: any) => {
	if (str) {
		return str.replace(find, replace);
	}
	return str;
}