import React, { useState } from "react";
import { observer } from "mobx-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Dropdown, Menu } from "antd";
import useStore from "../../../store";
// import { ClientLogIn } from "../../../components/ClientLogin/Component/validation";
import { ReactComponent as UserIcon } from "../../../assets/images/icons/userIcon.svg";
// import { ReactComponent as UserImg } from "../../../assets/images/userImg.svg";
import ClientLoginModal from "../../ClientLogin/Component/ClientLoginModal";
import ChangePasswordModal from "../../ClientLogin/Component/ChangePasswordModal";
import { ReactComponent as LoginImg } from "../../../assets/images/account-booking-icon.svg";
import { ReactComponent as ChangePw } from "../../../assets/images/change-password.svg";
// import {ReactComponent as NewBooking} from "../../../assets/images/new-booking-icon.svg";
import { ReactComponent as Logout } from "../../../assets/images/logout-icon.svg";
import NewBookingModal from "../../ClientLogin/Component/NewBookingModal";
import LogoutModal from "../../ClientLogin/Component/LogoutModal";
import { Constant } from "../../../config/Constant";
// import ResetPassword from "../../ClientLogin/Component/ResetPassword";

const HeaderMenuComponent: React.FC<any> = observer((props) => {
	const { menuList } = props;
	const { AUTH } = useStore();
	const { userDetails } = AUTH;
	const [clientmodalVisible, setClientModal1Visible] = useState(false);
	const location = useLocation();
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [bookingModalvisible, setBookingModalvisible] = useState(false);
	const [logoutModalvisible, setLogoutModalvisible] = useState(false);
	const handleDelModal = () => {
		setIsOpen(!isOpen);
		setIsDropdownVisible(!isOpen);
	};
	const handleBookingOpen = () => {
		setBookingModalvisible(true);
		setIsDropdownVisible(false);
	}
	const handleLogoutOpen = () => {
		setLogoutModalvisible(true);
		setIsDropdownVisible(false);
	}
	// const [resetmodalVisible, setResetModalVisible] = useState(false);
	const navigate = useNavigate();
	const handleClientOpen = () => {
		setClientModal1Visible(true);
		// setResetModalVisible(false);
	};
	const handleClientClose = () => {
		// setResetModalVisible(true);
		setClientModal1Visible(false);
	};
	const handleModalClose = () => {
		setClientModal1Visible(false);
		setBookingModalvisible(false);
		setLogoutModalvisible(false)
		// setResetModalVisible(false);
	};
	// const handleLogout = () => {
	// 	doLogout().then((data: any) => {
	// 		ClientLogIn.clientNotify.success({
	// 			message: data.STATUS.NOTIFICATION[0],
	// 		});
	// 			navigate("/")
	// 	});
	// }
	const handleAccount = () => {
		navigate("/my-account")
		setIsDropdownVisible(false);
	}
	const isActiveMenu = (url: string): boolean => {
		return location.pathname === url;
	};

	const menu = (
		<div className="logininfo">
			<div className="clientInfo">
				<div className="clientIcon">
					{/* <UserImg /> */}
					<Avatar src={(Constant.S3BUCKET_API_BASEURL + `user_avatars/avatar.png`)} style={{ height: "auto", width: "auto" }} />
				</div>
				<div className="clientDetails">
					<ul>
						<li>Name : <span>{userDetails?.name}</span></li>
						<li>Role : <span>{userDetails?.role?.name}</span></li>
						{/* <li>Level : <span>1</span></li> */}
					</ul>
				</div>
			</div>
			<Menu className="accountMenu">
				<li onClick={handleAccount}> <div className="iconImg"><LoginImg /></div> My Account</li>
				{/* <Divider/> */}
				<li onClick={() => handleDelModal()}><div className="iconImg"><ChangePw /></div>Change Password</li>
				{/* <li onClick={() => handleBookingOpen()}><div className="iconImg"><NewBooking/></div>New Booking</li> */}
				<li></li>
				{/* <Divider/> */}
				<li onClick={() => handleLogoutOpen()}><div className="iconImg"><Logout /></div>Logout</li>
			</Menu>
		</div>
	);
	const isToken = localStorage.getItem('token')
	return menuList ? (
		<>
			<div className="menuContainer">
				<ul className="menuWrapper">
					{menuList.map((data: any) => {
						return (
							<li key={data.id}>
								<Link to={data.url} className={isActiveMenu(data.url) ? 'active' : ""}>{data.title}</Link>

								<ul className="submenu">
									{data.sub_menu.map((item: any) => {
										return (
											<li key={item.id}>
												<Link to={item.url} className={isActiveMenu(item.url) ? 'active' : ""}>
													{item.title}
												</Link>
											</li>
										);
									})}
								</ul>

							</li>

						);
					})
					}
					{isToken ?
						<Dropdown overlay={menu} trigger={['click']} open={!isOpen && isDropdownVisible} // Only show if modal isn't open
							onVisibleChange={(visible) => setIsDropdownVisible(visible)} destroyPopupOnHide={true} placement="bottomRight" >
							<Button className="userIcon"><UserIcon className="icon" height={20} width={20} /></Button>
						</Dropdown>
						:
						<li onClick={handleClientOpen} key={8} className="clnt-btn">
							Client Login
						</li>
					}
					<ClientLoginModal
						visible={clientmodalVisible}
						close={handleModalClose}
						handleClientOpen={handleClientClose}
					/>
					<ChangePasswordModal
						handleCancel={handleDelModal}
						open={isOpen}
					/>
					<NewBookingModal handleCancel={handleBookingOpen} close={handleModalClose} visible={bookingModalvisible} />
					<LogoutModal handleClientOpen={handleLogoutOpen} close={handleModalClose} visible={logoutModalvisible} />
					{/* <ResetPassword
						visible={resetmodalVisible}
						close={handleModalClose}
						handleClientOpen={handleClientOpen}
					/> */}
				</ul>
			</div >
		</>
	) : null;
});
export default HeaderMenuComponent;
