import { notification } from "antd";
import { Messages } from "../../../config/Message/Common";
import { replaceString } from "../../../config/Global";
import { Constant } from "../../../config/Constant";

export const ClientLogIn = {
  validation: {
    clientUserName: [
      { required: true, message: Messages.USER_NAME },
    ],
    clientPassword: [
      { required: true, message: Messages.PASSWORD },
      // {
      //     pattern: Regex.charactersSpace,
      //     message: Messages.CHARACTER,
      // },
    ],
    clientLocation:[
      { required: true, message: Messages.CLIENTLOCATION },
    ],
    fromToDate: [
      { required: true, message: Messages.CLIENTDATE },
      () => ({
        validator(_: any, value: any) {
          if (value) {
            const [fromDate, toDate] = value;
            const days = toDate.diff(fromDate, 'days');
            if (days && days >= 31) {
              return Promise.reject(replaceString(Messages.DATE_RANGE_ERROR, "#DAYS#", Constant.FORMDATE));
            }
            return Promise.resolve();
          }
          else {
            return Promise.resolve();
          }
        }
      })
    ],
  },
  clientNotify: {
    success: (data: any) => {
      notification.success({ placement: "bottomRight", duration: 3, ...data });
    },
    error: (data: any) => {
      notification.error({ placement: "bottomRight", duration: 3, ...data });
    },
  }
};
export const ChangePw = {
  validation: {
    newPassword: [
      { required: true, message: Messages.NEWPASSWORD },
      () => ({
        validator(_: any, value: any) {
          // console.log("locationName", value);
          if (value && value.length >= 6) {
            return Promise.resolve();
          }
          return Promise.reject(Messages.MINPASSWORD);
        },
      }),
    ],
    confirmPassword: [
      { required: true, message: Messages.CONFIRMPASSWORD },
      { min: 6, message: Messages.MINPASSWORD },
      { max: 15, message:  Messages.MAXPASSWORD },
      ({ getFieldValue }:any) => ({
        validator(_: any, value: any) {
            const newPassword = getFieldValue('password');
            if (!value || newPassword === value) {
              return Promise.resolve();
            }
            return Promise.reject(Messages.CONFIRMPASSWORD);
          }
      }),
    ],
  },
};