import React from 'react';
import { Modal } from 'antd';

interface AddProps {
  handleCancel: () => void;
  close: () => void;
  visible?: boolean;
}
const NewBookingModal: React.FC<AddProps> = ({ close, visible
  //  handleModal2Open 
}) => {
  return (
    <>
      <Modal title="New Booking" open={visible} centered onCancel={close} footer={false} className='clientModal' destroyOnClose>
        <h1>Comming Soon...</h1>
      </Modal>
    </>
  );
};

export default NewBookingModal;