import { Col, Divider,  Row, Space} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useStore from "../../store";
import PageSpinner from "../PageSpinner/PageSpinner";
import { Messages } from "../../config/Message/Common";

const VerifyEmail: React.FC = () => {
    const token = useParams();
    const { AUTH } = useStore();
    const { verifyEmailToken } = AUTH;
    const [message, setMessage] = useState('');
    const [img, setImg] = useState('');
    useEffect(() => {
        verifyEmailToken(token)
            .then((data: any) => {
                setImg(data.img)
                setMessage(data.STATUS.NOTIFICATION[0])
                // setEmailToken(false);
            })
            .catch((res: any) => {
                setImg(res?.data?.img)
                setMessage(res.data.STATUS.NOTIFICATION[0])
                // setEmailToken(false);
            });
    }, []);
    return token ? (
        <section className="verifyEmailSection">
            <div className="container" >
                <div className="verifyEmailPage">
                <Row>
                    <Col>
                        <img src={img} alt="" className="image"/>
                    </Col>
                </Row>
                    <Row>
                        <Col>
                            {message && <h2 dangerouslySetInnerHTML={{ __html: message }}></h2> }
                        </Col>
                    </Row>
                        <Space align="center" split={<Divider type="vertical" />}>
                            <Link to="/" type="link" className="commanBtn">
                                Back Home
                            </Link>
                        </Space>
                    
                </div>
            </div>
        </section>
    ) ?? <PageSpinner /> : <h2>{Messages.PAGEERROR}</h2>
}
export default VerifyEmail;