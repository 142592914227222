import { Button, Col, Divider,  Row, Space} from "antd";
import React, { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import useStore from "../../store";
import PageSpinner from "../PageSpinner/PageSpinner";
import { Messages } from "../../config/Message/Common";
import ClientLoginModal from "../ClientLogin/Component/ClientLoginModal";


const VerifyEmailUser: React.FC<any> = () => {
       
    const token = useParams();
    const { AUTH } = useStore();
    const { verifyEmailUserToken } = AUTH;
    const [message, setMessage] = useState('');
    const [img, setImg] = useState('');
    const [clientmodalVisible, setClientModal1Visible] = useState(false);
    const handleClientOpen = () => {
		setClientModal1Visible(true);
			// setResetModalVisible(false);
	};
	const handleClientClose = () => {
		// setResetModalVisible(true);
		setClientModal1Visible(false);
		};
	const handleModalClose = () => {
		setClientModal1Visible(false);
		// setResetModalVisible(false);
		};
    useEffect(() => {
        verifyEmailUserToken(token)
            .then((data: any) => {
                setImg(data.img)
                setMessage(data.STATUS.NOTIFICATION[0])
                // setEmailToken(false);
            })
            .catch((res: any) => {
                setImg(res?.data?.img)
                setMessage(res.data.STATUS.NOTIFICATION[0])
                // setEmailToken(false);
            });
    }, []);
    return token ? (
        <section className="verifyEmailSection">
            <div className="container" >
                <div className="verifyEmailPage">
                <Row>
                    <Col>
                        <img src={img} alt="" className="image"/>
                    </Col>
                </Row>
                    <Row>
                        <Col>
                            {message && <h2 dangerouslySetInnerHTML={{ __html: message }}></h2> }
                        </Col>
                    </Row>
                        <Space align="center" split={<Divider type="vertical" />}>
                             <Button onClick={handleClientOpen} key={8} className="commanBtn">
                               Login Now
                            </Button>
                           
                        </Space>
                        <ClientLoginModal
						visible={clientmodalVisible}
						close={handleModalClose}
						handleClientOpen={handleClientClose}
					/>
                </div>
            </div>
        </section>
    ) ?? <PageSpinner /> : <h2>{Messages.PAGEERROR}</h2>
}
export default VerifyEmailUser;