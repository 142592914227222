import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";

export default class ChannelPartnerStore {
    public frenchiesData: any;
    public stateData: any;

    constructor() {
        makeAutoObservable(this);
    }
    public handleFrenchiesForm = async (payload: any): Promise<any> => {
        return await axios
            .post(`${API_URL.PAGES.FRANCHISEE}`, payload)
            .then(({ data }) => {
                return data;
            })
            .catch(({ data }) => {
                return Promise.reject(data);
            });
    };
    public setStateData = (data: any) => {
        this.stateData = data
    }
    public getStateList = async (): Promise<any> => {
        return await axios
            .post(API_URL.PAGES.STATELIST)
            .then(({ data }) => {
                this.setStateData(data.states)
                return data;
            })
            .catch(({ data }) => {
                return Promise.reject(data);
            });
    };
}
