export const Constant = {
    S3BUCKET_API_BASEURL: (process.env.React_APP_S3bucket) ? process.env.React_APP_S3bucket : "https://cmsmanagemant.s3.ap-south-1.amazonaws.com/",
    FORMDATE: 31,
    SHIPMENT_3RD_DIGIT:3,
    SHIPMENT_7TH_DIGIT:8,
    CLIENT_TYPE: {
        NORMAL: 1,
        CORPORATE: 2,
        BARTER: 3,
    },
};


