import { Modal } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { Constant } from "../../../../../config/Constant";

interface Modal {
	visible?: boolean,
	onClose?: any,
	modalTitle: any,
	modalContent: any,
	modalImg: any
}

const PublicNotice: React.FC<Modal> = observer(({
	visible, onClose, modalTitle,
	modalContent, modalImg
}) => {


	return (
		<>
			<Modal
				open={visible}
				onCancel={onClose}
				centered
				className="publicNot-content"
				// width={"70%"}
				title={modalTitle}
				footer={false}
			>
				<p dangerouslySetInnerHTML={{ __html: modalContent }} />
				<div className="modalImg">
					{modalImg
						? (
							<img src={Constant.S3BUCKET_API_BASEURL + modalImg} alt="Communication Image" />
						) : null
					}
				</div>

			</Modal>
		</>
	);
});
export default PublicNotice;
