import React from 'react';
import { Button, Col, Divider, Form, Modal, Row } from 'antd';
import { InputBox } from '../../AntdAddons';
import LogoComponent from '../../Header/Components/LogoComponent';
import useStore from '../../../store';
import { ClientLogIn } from './validation';
import { useNavigate } from 'react-router-dom';

interface AddProps {
    close: () => void;
    visible?: boolean;
    handleClientOpen: () => void;
    closeDrawer?:any;
    open?:any
}
const ClientLoginModal: React.FC<AddProps> = ({ visible, close,closeDrawer,open
  //  handleModal2Open 
}) => {
    const [form] = Form.useForm();
    const { AUTH, ROOT: { assignErrorToInput } } = useStore();
    const { doLogin } = AUTH;
    const navigate = useNavigate();
    const handleSubmit = (formData: any) => {
        doLogin(formData)
            .then((res: any) => {
                form.resetFields();
                navigate('/my-account');
                close();
                ClientLogIn.clientNotify.success({
                message: res.STATUS.NOTIFICATION[0],
                })
                if(open){
                    closeDrawer();
                }
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS)
                ClientLogIn.clientNotify.error({
                message: error.STATUS.NOTIFICATION[0],
                });
                localStorage.clear();
            });
    };
  return (
    <>
      <Modal onCancel={close} open={visible} footer={false} className='clientModal' destroyOnClose>
        <div className="loginForm" >
            <div className="loginFormWrap">
                <LogoComponent />
                <Divider />
                <Form form={form} className='clientLogin'
                onFinish={handleSubmit}>
                <Row>
                    <Col span={24}>
                        <InputBox.Text
                            name="username"
                            rules={ClientLogIn.validation.clientUserName}
                            placeholder="example@domain.com"
                            label="Username"
                        />
                    </Col>
                    <Col span={24}>
                        <InputBox.Password
                            name="password"
                            rules={ClientLogIn.validation.clientPassword}
                            placeholder="xxxxxxx"
                            label="Password"
                        />
                    </Col>
                </Row>
                <div>
                    <Button className="commanBtn" htmlType='submit'>LogIn </Button>
                </div>
                </Form>
            </div>
        </div>
      </Modal>
    </>
  );
};
export default ClientLoginModal;