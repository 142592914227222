import React from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { Messages } from "../../../../config/Message/Common";
import { useParams } from "react-router-dom";

interface DataErrorPageProps {
    notMsg: string;
  }
const DataErrorPage: React.FC<DataErrorPageProps> = observer(({notMsg}) => {
    const { id } = useParams<{ id: string }>();

    return (
        <section className="section">
            <div className="container">
                <Row gutter={15} className="trackingError">
                    <Col span={24}>
                        <div className="trackblcHeader ">
                            <h4> Shipment Information:
                                <span>{id ?? Messages.NOT_DATA}</span>
                            </h4>

                        </div>
                    </Col>
                    <p className="errorPera">
                        {notMsg ?? Messages.NO_RECORD}
                    </p>
                </Row>
            </div>
        </section>
    );
});
export default DataErrorPage;
