import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CommanTrackIcon } from "../../assets/images/icons/commanTrackIcon.svg";

const TrackBtn: React.FC = () => {
    const navigate = useNavigate();
    // const pageURL = window.location.pathname

    const scrollToTop = () => {
        window.scrollTo({
            top: 700,
            behavior: "smooth",
        });
    };
    const handleTrack = () => {
        navigate("/");
        scrollToTop();
    }
    return (
        <div className="trackCommanBtn">
            <div className="trackIcon" onClick={handleTrack}>
            <CommanTrackIcon className="trackSvg"/>
            </div>
            <Button onClick={handleTrack}>Track Your Shipment</Button>
        </div>
    )
};

export default TrackBtn;