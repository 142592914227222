import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import TrackShipmentBanner from "./TrackShipmentBanner";
import { useParams } from "react-router-dom";
import useStore from "../../../../store";
import { notification } from "antd";
import InternationalList from "./InternationalList";

const InternationalTrack: React.FC = observer(() => {
	const { id } = useParams();
	const { TRACKDATA } = useStore();
	const [notMsg, setNotMsg] = useState();

	useEffect(() => {
		if (id) {
			getData(id);
		}
	}, [id]);

	let travlingData: any = null;
	let bookingData: any = null;

	const getData = async (id: any) => {
		let error: any = false;
		if (id) {
			travlingData = await TRACKDATA.getTrackData({ 	
				con_no: id,
				purpose: "8",
				is_external_tracking: 1,
				is_international: 1
			})
				.then((data: any) => {
					error = false;
					return data;
				})
				.catch((e: any) => {
					setNotMsg(e?.STATUS?.NOTIFICATION[0])
					notification.info({
						message: e?.STATUS?.NOTIFICATION[0],
						placement: "bottomRight",
					});
					error = true;
				});
			if (error === false) {
				bookingData = await TRACKDATA.getTrackData({
					con_no: id,
					purpose: "7",
					is_external_tracking: 1,
					is_international: 1
				});
			}
		}
		const AllData = { ...travlingData, ...bookingData };
		TRACKDATA.trackData = AllData;
		TRACKDATA.setProccedTrackData(AllData)
	};

	return (
		<>
			<TrackShipmentBanner />
			<InternationalList listData={TRACKDATA.trackData} notMsg={notMsg} />

		</>
	);
});
export default InternationalTrack;
