import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";

export default class ContactStore {
	public contactData: any;

	constructor() {
		makeAutoObservable(this);
	}

	public getContactData = async (): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.CONTACT)
			.then(({ data }) => {
				this.contactData = data?.data;
				return this.contactData;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public handleContactForm = async (payload: any): Promise<any> => {
		return await axios
			.post(`${API_URL.PAGES.CONTACTFORM}?${payload}`)
			.then(({ data }) => {
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
}
