import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";



export default class ServiceStore {
	public serviceData: any;
    constructor() {
		makeAutoObservable(this);
	}

	public getServiceData = async (): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.SERVICE)
			.then(({ data }) => {
				this.serviceData = data?.data;						
				return this.serviceData;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
}
