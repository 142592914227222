import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";

export default class HomeStore {
	public homeData: any;
	public tabValues: any;
	constructor() {
		makeAutoObservable(this);
	}

	public getHomeData = async (): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.HOME)
			.then(({ data }) => {
				this.homeData = data?.data;
				if(this.homeData && this.homeData?.our_mission_section?.sub_our_mission_section){
					this.tabValues = this.homeData?.our_mission_section?.sub_our_mission_section
				}				
				return this.homeData;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
}
