import React, { useEffect } from "react";
import ListComponent from "./Listing/ListComponent";


const CLientLogin: React.FC = () => {
    const scrollToTop = () => {
		window.scrollTo({
			top:0,
			behavior: "smooth",
		});
	};
	useEffect(()=>{
        scrollToTop();
    },[])
    return (
        
        <section >
            <div className="container listComponent" >
                <ListComponent />
            </div>
        </section>
    );
}

export default CLientLogin;

