import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Layout } from "antd";
import useStore from "../../store";
import PageSpinner from "../PageSpinner/PageSpinner";
import HeaderMenuComponent from "../../components/Header/Components/HeaderMenuComponent";
import SocialIcons from "../../components/Header/Components/SocialIcons";
import ResponsiveMenu from "./Components/ResponsiveMenu";
import LogoComponent from "./Components/LogoComponent";
import { ReactComponent as MenuIcon } from "../../assets/images/icons/barIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import PublicNotice from "../../views/app_pages/Home/components/Modal/PublicNotice";


const HeaderView: React.FC = observer(() => {
	const { Header } = Layout;
	const {
		AUTH: { headerData, getHeaderData, getPopupData },
	} = useStore();
	const menuList = headerData?.menu_section;
	const socialList = headerData?.social_section;
	const [click, setClick] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [modalVisibilities, setModalVisibilities] = useState<{ [key: number]: boolean }>({});
	const [modalData, setModalData] = useState([]);

	useEffect(() => {
		getHeaderData();
		getPopupData().then((data: any) => {
			if(data?.communication?.popup_message && data?.communication?.popup_message.length > 0){
				setModalData(data?.communication?.popup_message)
			}
		});
	}, [getHeaderData, getPopupData]);

	const openModalsBasedOnFrequency = (data: any) => {
		setTimeout(() => {
			const modalsToOpen = modalData && modalData.filter((value: any) => value?.frequency === data?.frequency);
			if (modalsToOpen.length > 0) {
				modalsToOpen.forEach((modal: any) => {
					setModalVisibilities((prevVisibilities) => ({
						...prevVisibilities,
						[modal?.id]: true,

					}));
				});

			}
		}, data?.frequency * 60 * 1000);
	};

	useEffect(() => {
		modalData?.forEach((frequency: any) => {
			openModalsBasedOnFrequency(frequency);
		});
	}, [modalData]);

	const closeModal = (id: number) => {
		setModalVisibilities((prevVisibilities) => ({
			...prevVisibilities,
			[id]: false,
		}));
	};

	const handleClick = () => {
		setClick(!click);
		setIsOpen(!isOpen);
	};
	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
	});

	const isSticky = () => {
		const header = document.querySelector(".main__page__header");
		const scrollTop = window.scrollY;
		scrollTop >= 100
			? header?.classList.add("isSticky")
			: header?.classList.remove("isSticky");
	};

	const close = () => {
		setIsOpen(false);
		setClick(false);
	};

	return headerData ? (
		<>
			<Header className="main__page__header">
				<div className="container headerContainer">
					<LogoComponent />
					{menuList ? <HeaderMenuComponent menuList={menuList} /> : null}
					{socialList ? <SocialIcons socialList={socialList} /> : null}
				</div>

				<div className="menuToggle" onClick={handleClick}>
					{!click ? <MenuIcon /> : <CloseIcon />}
				</div>

				<ResponsiveMenu closeDrawer={close} open={isOpen} menuList={menuList} />

			</Header>
			{modalData?.map((modal: any, index: any) => (
				<PublicNotice
					key={index}
					modalTitle={modal?.title}
					modalContent={modal?.message}
					visible={modalVisibilities[modal?.id] || false}
					onClose={() => closeModal(modal?.id)}
					modalImg={modal?.att_file}
				/>
			))}
		</>
	) : (
		<PageSpinner />
	);
});

export default HeaderView;
