import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";
import { converViewsAddress } from "../../../config/Global";
export default class SearchLocationStore {
	public locationData: any = null;
	public locationDataTotal: any = null;
	public pincodeData: any = null;
	public centerData: any;
	public selected_id: any;

	constructor() {
		makeAutoObservable(this);
	}

	setCenterData = (data: any) => {
		if (data) {
			data.address = converViewsAddress(data);
		}
		this.centerData = data;
	};

	setPageData = (data: any) => {
		this.locationDataTotal = data;
	};

	public getLocationData = async (payload: any): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.LOCATION, payload)
			.then(({ data }) => {
				this.locationData = data?.list?.data;
				this.locationDataTotal = data?.list?.total;
				this.setPageData;
				return this.locationData;
			})
			.catch(({ error }) => {
				console.error("Something bad happened");
				console.error(error);
	});
	};

	public getCenterDetail = async (id: any): Promise<any> => {
		return await axios
			.post(`${API_URL.PAGES.CENTERDETAIL}/${id}`)
			.then(({ data }) => {
				this.setCenterData(data?.data);

				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
}
