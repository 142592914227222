import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";

export class ClientStore {
    public clientListData: any = null;
    public locationDataTotal: any = null;
    public lckFlags: any = null;
    public podZipData: any = null;
    public podImg: any = null;
    public locationData: any = null;
   
    constructor() {
        makeAutoObservable(this);
    }
    setPageData = (data: any) => {
        this.locationDataTotal = data;
    };
    setClientListData = (data: any) => {
        this.clientListData = data;
    };
    setClientLocation = (data: any) => {
        this.locationData = data
    }
    public getClientData = async (payload: any): Promise<any> => {
        return await axios
            .post(API_URL.BOOKING_LIST, payload)
            .then(({ data }) => {
                this.clientListData = data?.list?.data;
                this.locationDataTotal = data?.list?.total;
                this.lckFlags = data?.lck_flags;
                return data;
            })
            .catch(({ data }) => {
                return Promise.reject(data);
            });
    };
    public getDownloadPODData = async (payload: any): Promise<any> => {
        return await axios
            .post(API_URL.DOWNLOAD_POD, payload)
            .then((data) => {
                this.podZipData = data.data.pod;
            })
            .catch(({ data }) => {
                return Promise.reject(data);
            });
    };
  
    public getLocationList = async (payload: any): Promise<any> => {
        return await axios
            .post(`${API_URL.PAGES.LOCATIONLIST}`, payload)
            .then(({ data }) => {
                this.setClientLocation(data.account_party)
                return data;
                
            })
            .catch(({ data }) => {
                return Promise.reject(data);
            });
    };

}
export class ChangePasswordStore {
    public clientListData: any = null;
    public locationDataTotal: any = null;
    constructor() {
        makeAutoObservable(this);
    }
    setPageData = (data: any) => {
        this.locationDataTotal = data;
    };
    public changePassword = async (payload: any): Promise<any> => {
        return await axios
            .post(API_URL.CHANGE_PASSWORD, payload)
            .then(({ data }) => {
                // this.clientListData = data?.list?.data;
                //this.locationDataTotal = data?.list?.total;
                return data;
            })
            .catch(({ data }) => {
                return Promise.reject(data);
            });
    };
}