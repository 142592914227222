import React from "react";
import LOGO_IMAGE from "../../../assets/images/CompanyLogo.png";
import { Link } from "react-router-dom";

const LogoComponent: React.FC = () => {
	return (
		<div className="logo__wrapper">
		<Link to="/">
		<img src={LOGO_IMAGE} alt="LOGO" />
		</Link>
			
		</div>
	);
};

export default LogoComponent;
