import { Col, Row, Timeline } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { Messages } from "../../../../config/Message/Common";
import { displayFormats, smoothScrollTo } from "../../../../config/Global";
import moment from "moment";
import DataErrorPage from "./DataErrorPage";

const InternationalList: React.FC<any> = observer(({ listData, notMsg }) => {
    const ConsignInfo = listData?.con_no;
    const BookingStatus = listData?.booking?.bk_status;
    const bookingDetails = listData?.booking;
    const travDetails = listData?.traveling_info;
    const [centerMobile, setCenterMobile] = useState([]);
    const trackInfoRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (bookingDetails?.center_mobile) {
            const center_mobile_arr = bookingDetails?.center_mobile?.split(",");
            setCenterMobile(center_mobile_arr);
        }
    }, [bookingDetails]);
    useEffect(() => {
        smoothScrollTo(trackInfoRef);
    });
    return (
        <section className="section trackConsignment" ref={trackInfoRef}>
            {
                (listData?.booking || listData?.traveling_info?.length > 0) ?
                    <div className="container">
                        <Row>
                            <Col span={12}>
                                <div className="blcHeader">
                                    <h4> International Information :
                                        <span>{ConsignInfo ?? Messages.NOT_DATA}</span>
                                    </h4>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="blcHeader">
                                    <h4> Status:
                                        <span>{BookingStatus ?? Messages.NOT_DATA}</span>
                                    </h4>
                                </div>
                            </Col>
                        </Row>
                        <Row className="internationalRow">
                            <Col span={24}>
                                <div className="dataTitle">Booking Information</div>
                            </Col>
                            <div className="internationalBooking">
                                <Col span={24} className="internationalCol">
                                    {
                                        bookingDetails ? (
                                            <>
                                                <Row gutter={16}>
                                                    <Col xs={12} sm={6}>
                                                        Booking Location :
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        {bookingDetails?.center
                                                            ?? Messages.NOT_DATA}
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        Booking State :
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        {bookingDetails?.bk_state
                                                            ?? Messages.NOT_DATA}
                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col xs={12} sm={6}>
                                                        Location&lsquo;s Phone :
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        {centerMobile.map((data: any, index: any, array: string[]) => {
                                                            const isLastElement = index === array.length - 1;
                                                            return centerMobile ? <> <a key={index} href={"tel:" + data}>{data}</a>{isLastElement ? "" : ", "} </> : Messages.NOT_DATA
                                                        })
                                                        }
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        Booking Date :
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        {bookingDetails?.bk_date ? moment(bookingDetails?.bk_date).format(displayFormats.DATE_TIME_FORMAT2) : Messages.NOT_DATA}
                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col xs={12} sm={6}>
                                                        Shipment No. :
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        {bookingDetails?.con_no
                                                            ?? Messages.NOT_DATA}
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        Shipment Type :
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        {bookingDetails?.product ?? Messages.NOT_DATA}

                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col xs={12} sm={6}>
                                                        Consignee :
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        {bookingDetails?.r_name ?? Messages.NOT_DATA}
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        Destination :
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        {bookingDetails?.r_city ?? Messages.NOT_DATA}
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : ("")
                                    }
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {/* <div className="internationalTableList">
                                    <table className="internationalTable">
                                        <thead>
                                            <tr>
                                                <th>Date Time</th>
                                                <th>Location</th>
                                                <th>Progress</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {travDetails && travDetails.map((data: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{data.timestamp}</td>
                                                    <td>{data.location}</td>
                                                    <td>{data.message}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div> */}
                                <div className="timeLine internationalTableList">
                                    <h4>Tracking History</h4>

                                    {travDetails?.length > 0 ? (
                                        <>
                                            <Timeline mode={"left"}>

                                                {travDetails?.map((data: any, index: number) => {

                                                    return (
                                                        <Timeline.Item key={index} label={data?.created ? moment(data?.created).format(displayFormats.DATE_TIME_FORMAT2) : Messages.NOT_DATA}>
                                                            {data.result}
                                                        </Timeline.Item>
                                                    );
                                                })}
                                            </Timeline>
                                        </>
                                    ) : (
                                        <Row gutter={16}>
                                            <Col span={24} className="nodata">
                                                {Messages.NO_RECORD}
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div> : <DataErrorPage notMsg={notMsg} />
            }
        </section>
    );
});

export default InternationalList;
