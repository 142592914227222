import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";



export default class NetworkStore {
	public networkData: any;
    constructor() {
		makeAutoObservable(this);
	}

	public getNetworkData = async (): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.NETWORK)
			.then(({ data }) => {
				this.networkData = data?.data;						
				return this.networkData;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
}
