import React, { useEffect, useState } from "react";
import { ReactComponent as Handsakeoffer } from "../../assets/images/handsakeoffer.svg";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const OfferBtn: React.FC = () => {
  const pageURL = window.location.pathname
  const navigate = useNavigate();
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const closeFloatAlert = () => {
    setIsAlertVisible(false);
  };

  const showFloatAlert = () => {
    setIsAlertVisible(true);
  };
  const handlefranchisee = () => {
    navigate("/franchisee-enquiry");
  }
  useEffect(() => {
    if (pageURL === "/franchisee-enquiry") {
      setIsAlertVisible(false)
    } else
      setIsAlertVisible(true);
  }, [pageURL]);

  return (
    <div className="offerBtnContainer">
      {isAlertVisible && (
        <div className="floatAlert">
          <div className="floatAlertContent">
            <p>   Hurry! Golden Opportunity.</p>
            <p>  We are offering franchisee for
              All Metros and Semi-Metros in India.</p>
            <div onClick={handlefranchisee} className="link">
              Click Here
            </div>
          </div>
          <div className="floatAlertClose" onClick={closeFloatAlert}>
            <CloseOutlined />
          </div>
        </div>
      )}
      <div className={`floatButton ${isAlertVisible ? "hidden" : ""}`} onClick={showFloatAlert}>
        <Handsakeoffer />
      </div>
    </div>


  );
};

export default OfferBtn;
