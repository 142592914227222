export const Messages = {
	CHARACTER: "Please enter characters only",
	LOCATION_MINLENGTH: "Please enter minimum three characters",
	LOCATION_NAME: "Please enter Location Name",
	LOCATION_VALIDNAME: "Please enter Valid Location Name",
	PINCODE: "Please enter Pincode Number",
	VALID_PINCODE: "Please enter Pincode Number",
	PINCODE_LENGTH: "Please enter six digit",
	NUMBERS: "Please enter Digits only",
	SHIPMENT_NUMBER: "Shipment Number is required",
	SHIPMENT_LENGTH: "Shipment No. is either 11 or 13 digit",
	NOT_DATA: "Not Available",
	NA: "N/A",
	NO_RECORD: "No Record Found",
	FIRST_NAME: "First Name is required",
	LAST_NAME: "Last Name is required",
	FULL_NAME: "Full Name is required",
	MOBILE_NUMBER: "Mobile Number is required",
	MOBILE_VALID: "Enter valid mobile number",
	PHONE_NUMBER: "Phone Number is required",
	PHONE_VALID: "Enter valid phone number",
	EMAIL: "Email address is required.",
	ERROR_EMAIL: "Invalid email address",
	MESSAGES_DIS: "Message/Description is required",
	CAPTCHA: "It cannot be blank.",
	NOT_MATCH_CAPTCHA: "CAPTCHA does not match",
	USER_NAME: "Username is required",
	PASSWORD: "Password is required",
	DATE_RANGE_ERROR: "Please Select only 30 days Data",
	NEWPASSWORD: "Please enter a new password",
	CONFIRMPASSWORD: "Confirm Password is not matched with New Password",
	MINPASSWORD: "Password minimum length must be 6 character.",
	MAXPASSWORD: "Password maximum length must be 15 character.",
	PAGEERROR: "Something went wrong. Please try after sometime.",
	CURRENT_BUSINESS: "Current Business is required.",
	EXP_YEAR: "Experience in Year is required.",
	CURRENTBUSINESS: "Man Power is required.",
	CURRENTADDRESS: "Address is required.",
	CURRENTCITY: "City is required.",
	CURRENTSTATE: "State is required.",
	CURRENTPINCODE: "Pincode is required.",
	REQUIRED_ADDRESS: "Office Location is required.",
	REQUIRED_CITY: "Office City is required.",
	REQUIRED_STATE: "Office State is required.",
	REQUIRED_PINCODE: "Pincode is required.",
	INVALID_3RD_DIGIT:"Invalid Shipment Number",
	INTERNATIONAL_NUMBER:"Shipment No. is either 7 or 20 digit",
	MAPURL: "https://www.google.com/maps?q=",
	CLIENTLOCATION:"Please select Location",
	CLIENTDATE:"Please select Date",
	// PASSSUCCESS:"Password has been changed successfully."
};