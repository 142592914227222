import React from 'react';
import { Button, Col, Modal, Row } from 'antd';
import useStore from '../../../store';
import { ClientLogIn } from './validation';
import { useNavigate } from 'react-router-dom';

interface AddProps {
  handleClientOpen: () => void;
  close: () => void;
  visible?: boolean;
}
const LogoutModal: React.FC<AddProps> = ({ close, visible
  //  handleModal2Open 
}) => {
    const { AUTH } = useStore();
	const { doLogout} = AUTH;
    const navigate = useNavigate();
    const handleLogout = () => {
		doLogout().then((data: any) => {
			ClientLogIn.clientNotify.success({
				message: data.STATUS.NOTIFICATION[0],
			});
            navigate("/")
		});
	}
  return (
    <>
        <Modal title="Logout" open={visible} centered onCancel={close} footer={false} className='clientModal logoutModal' destroyOnClose>
            <div className="loginForm" >
                <div className="loginFormWrap">
                    <Row>
                        <Col span={24}><p>Are you sure you want to Logout?</p></Col>
                    </Row>
                    <Row className='inlineBtn' gutter={15}>
                        <Col span={8}>
                            <Button className="commanBtn" onClick={close}>No</Button>
                        </Col>
                        <Col  span={8}>
                            <Button className="commanBtn" htmlType="submit" form='form_item_path' onClick={handleLogout}>Yes</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    </>
  );
};
export default LogoutModal;