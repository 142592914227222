import { makeAutoObservable } from "mobx";
import axios from "axios";
import { doLoginType, userType } from "./AuthInterface";
import RootStore from "../RootStore/RootStore";
import API_URL from "../../config/ApiUrl";
export default class AuthStore {
	// Variables
	public user?: userType;
	public token?: any;
	public app_loading: boolean;
	public email?: string;
	private rootStore: RootStore;
	public headerData?: any;
	public footerData?: any;
	// public remember_me = null;
	public popupData?: any;
	public scrollingMsg?: any;
	public clientDetails?: any;
	public userDetails?: any;
	public privileges?: any
	currentToken = null;
	version: any;
	constructor() {
		this.app_loading = true;
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}
	setUserData = (data: any) => {
		this.user = data
	}
	setClientDetails = (data: any) => {
		this.clientDetails = data
	}
	setUserDetails = (data: any) => {
		this.userDetails = data
	}
	// Initiate Application Functions
	public InitializeApp = (): void => {
		this.rootStore.setAxiosBaseUrl();
		this.rootStore.setAxiosInterceptor();
		this.rootStore.setNotificationConfig();
		this.setupHeaders();
		// const remember_me = localStorage.getItem("remember_me");
		// this.remember_me = remember_me
		// 	? JSON.parse(stringDecryption(remember_me))
		// 	: null;
		const client = localStorage.getItem("client");
		if (client) {
			this.clientDetails = JSON.parse(client);
		}
		const user = localStorage.getItem("user");
		if (user) {
			this.userDetails = JSON.parse(user);
		}
		if(!this.privileges){
			this.setPrivileges(this.userDetails?.role?.privileges)
		}
	};
	public setupHeaders = (access_token?: string): void => {
		this.setAppLoading(true);
		this.rootStore
			.setAxiosHeaders(access_token)
			.then((token) => {
				// this.getPopupData()
				this.setToken(token);
				// this.fetchAuthUser();
			})
			.catch(() => {
				// this.getPopupData();
				this.setToken();
				this.setUser();
				this.setAppLoading(false);
			});
	};
	// Setter Functions
	public setUser = (value?: userType): void => {
		this.user = value;
	};
	public setToken = (value?: string): void => {
		this.token = value;
	};
	public setAppLoading = (value: boolean): void => {
		this.app_loading = value;
	};
	public setEmail = (value: string): void => {
		this.email = value;
	};
	public setPrivileges = (value: string): void => {
		this.privileges = value;
	};
	public getHeaderData = async (): Promise<any> => {
		return await axios
			.post(API_URL.HEADER)
			.then(({ data }) => {
				this.headerData = data?.header;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
	public getPopupData = async (): Promise<any> => {
		return await axios
			.get(API_URL.POPUP)
			.then(({ data }) => {
				this.popupData = data?.communication;
				this.scrollingMsg = data?.communication;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
	public getFooterData = async (): Promise<any> => {
		return await axios
			.post(API_URL.FOOTER)
			.then(({ data }) => {
				this.footerData = data?.footer;
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
	// call api for login
	public doLogin = async (payload: doLoginType): Promise<any> => {
		return await axios
			.post(API_URL.LOGIN, payload)
			.then(({ data }) => {
				// if (data.remember) {
				// 	const string = JSON.stringify({
				// 		username: data.username,
				// 		password: data.password,
				// 	});
				// 	localStorage.setItem("remember_me", stringEncryption(string));
				// }


				if (data) {
					this.setToken(data.token)
					this.setUserData(data)
					localStorage.setItem("token", (data.token))
					localStorage.setItem("client", JSON.stringify(data?.client))
					localStorage.setItem("user", JSON.stringify(data?.user))
					this.setClientDetails(data?.client);
					this.setUserDetails(data?.user)
					this.setPrivileges(data?.user.role.privileges)
					// window.location.replace('/my-account');
					// localStorage.setItem("center_id", JSON.stringify(data.client?.center_id));
				}
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
		// })
	};
	public doLogout = async (): Promise<any> => {
		return await axios
			.post(API_URL.LOGOUT)
			.then((data: any) => {
				this.setAppLoading(true);
				this.rootStore.resetStore();
				// const remember_me = localStorage.getItem("remember_me");
				// if (remember_me) {
				// 	localStorage.setItem("remember_me", remember_me);
				// }
				return data.data
			})
			.catch((data) => {
				this.setAppLoading(false);
				return Promise.reject(data);
			})
			.finally(() => this.setAppLoading(false));
	};
	public verifyEmailToken = async (
		token: any
	): Promise<any> => {
		//  console.log('store_token', token.token)
		return await axios
			// .post(API_URL.EMAIL_VERIFY(token.token))
			.post(API_URL.EMAIL_VERIFY, token)
			.then((data) => {
				return data.data;
			});
	};
	//user Email Verification 
	public verifyEmailUserToken = async (
		token: any
	): Promise<any> => {
		return await axios
			// .post(API_URL.EMAIL_VERIFY(token.token))
			.get(API_URL.USER_ACCOUNT_VERIFY(token.token))
			.then((data) => {
				return data.data;
			});
	};

	// check privileges
	public checkPrivileges = (permission_type: any) => {		
		if (this.privileges && this.privileges.includes(permission_type)) {
			return true;
		} else {
			return false;
		}
	};
}