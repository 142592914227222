import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../../config/ApiUrl";



export default class PrivacyStore {
	public privacyData: any;
	public bannerSection: any

	constructor() {
		makeAutoObservable(this);
	}
	setppData = (data?: any) => {
		this.privacyData = data
	}

	public getPrivacyData = async (): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.PRIVACY)
			.then(({ data }) => {
				this.bannerSection = data.data?.banner_section
				this.setppData(data?.data);
				return data
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};
}
