import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../../config/ApiUrl";

export default class TrackingStore {
	public trackData: any;
	public sendEmail: any;
	public proccedTrackData: any;
	public errorData: any;
	public trackBannerData: any;

	constructor() {
		makeAutoObservable(this);
	}

	public getTrackData = async (data: any): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.TRACKDATA, data)
			.then(({ data }) => {
				this.trackData = data?.view;
				return this.trackData;
			})
			.catch(({ data }) => {
				this.errorData = data?.STATUS;
				return Promise.reject(data);
			});
	};

	public setProccedTrackData = (value?: string): void => {
		this.proccedTrackData = value;
	};

	public getTrackBanner = async (): Promise<any> => {
		return await axios
			.post(API_URL.PAGES.TRACKDATABANNER)
			.then((data) => {
				this.trackBannerData = data?.data?.data?.banner_section;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public sendEmailTrack = async (payload: any): Promise<any> => {
        return await axios
			.post(API_URL.PAGES.SENDTRACKEMAIL, payload)
			.then(({ data }) => {
                return data;
            })
            .catch(({ data }) => {
                return Promise.reject(data);
            });
			
	};

}
